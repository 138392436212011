<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<!--                    筛选条件-->
			<a-row :gutter="[8, 8]">
				<a-col :span="5">
					<a-range-picker allowClear @change="timechange" />
				</a-col>
				<a-col :span="3">
					<a-tree-select
						:placeholder="l('Company')"
						allowClear
						multiple
						@change="handleChange"
						style="width: 100%"
						tree-default-expand-all
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</a-col>
				<a-col :span="3">
					<a-tree-select
						v-model="departmentId"
						multiple
						allowClear
						style="width: 100%"
						show-search
						:filterTreeNode="searchTree"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						@change="deptChange"
						tree-default-expand-all
					>
					</a-tree-select>
				</a-col>
				<a-col :span="3">
					<a-select
						mode="tags"
						show-search
						allowClear
						:placeholder="l('员工类型')"
						style="width: 100%"
						v-model="empType"
						@change="getData"
					>
						<a-select-option v-for="item in empTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="5" style="text-align: right">
					<a-input-search
						style="max-width: 200px;"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
				<a-col :span="3" style="line-height: 40px">
					<a-checkbox
						style="text-align: left; "
						v-model="isEmpState"
						@change="search"
						default-checked
						value="离职员工"
					>
						含离职员工
					</a-checkbox>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="23">
					<a-button
						class="ant-btn ant-btn-primary"
						@click="Export()"
						v-if="isGranted('check_in_record_excel')"
					>
						<a-icon type="download" />
						导出报表
					</a-button>
					<a-button type="primary" @click="Import()" v-if="isGranted('import_check_in_record')">
						<a-icon type="import" />
						导入打卡
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				@change="sorterChange"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import {
	CheckInRecordServiceProxy,
	DepartmentServiceProxy,
	CompanyServiceProxy,
} from '../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../shared/utils';
import moment from 'moment';
import { abpService } from '../../../shared/abp';
import ImportExcelModel from './import-excel-model/import-excel-model';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'check-in-record',
	mixins: [AppComponentBase],
	components: { ImportExcelModel },
	data() {
		return {
			AttGroupList: [],
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			isEmpState: true,
			//部门树
			DepTreeData: [],
			DepTree: [],
			date: undefined,
			startTime: undefined,
			endTime: undefined,
			// 表格
			columns: [
				{
					title: this.l('工号'),
					dataIndex: 'jobNumber',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('姓名'),
					dataIndex: 'realName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('公司'),
					dataIndex: 'company',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'company' },
				},
				{
					title: this.l('部门'),
					dataIndex: 'departmentName',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'departmentName' },
				},
				{
					title: this.l('员工类型'),
					dataIndex: 'empType',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'empType' },
				},
				{
					title: this.l('打卡方式'),
					dataIndex: 'checkType',
					align: 'center',
					sorter: true,
					ellipsis: true,
					scopedSlots: { customRender: 'checkType' },
				},
				{
					title: this.l('打卡时间'),
					dataIndex: 'checkTime',
					align: 'center',
					sorter: true,
					ellipsis: true,
					width: 200,
					scopedSlots: { customRender: 'checkTime' },
				},
			],

			tableData: [],
			empType: undefined,
			empTypeList: [],
		};
	},
	created() {
		this._CheckInRecordServiceProxy = new CheckInRecordServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
	},
	async mounted() {
		this.empTypeList = await Dic.getInstance().getDicAsync('EmpType');
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
		}
		this.getData();
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		getData() {
			this.loading = true;
			this._CheckInRecordServiceProxy
				.getPaged(
					this.startTime,
					this.endTime,
					this.comPanyId,
					this.departmentId,
					this.isEmpState,
					this.empType,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map(item=>{
						item.checkTime=item.checkTime?moment(item.checkTime).format("YYYY-MM-DD HH:mm"):"";
					});
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 日期选择timechange
		 */
		async timechange(date, time) {
			if (date.length > 0) {
				this.startTime = moment(time[0]);
				this.endTime = moment(time[1]);
				//await this.getCycle();
			} else {
				this.startTime = undefined;
				this.endTime = undefined;
			}
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.getData();
		},

		companyTreeInit() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.handleChange(this.companyTreeList[0].key);
					// }
				});
		},

		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},

		//公司下拉框
		handleChange(value) {
			this.comPanyId = value;
			this.pageNumber = 1;
			this.request.skipCount = 0;

			this.departmentId = undefined;

			this.getData();
			this.getDepTreeData();
		},
		deptChange(value) {
			this.pageNumber = 1;
			this.request.skipCount = 0;
			this.departmentId = value;
			this.getData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},

		/*
            导出表格
             */
		Export() {
			this._CheckInRecordServiceProxy
				.getToExcelFile(
					this.startTime,
					this.endTime,
					this.comPanyId,
					this.departmentId,
					this.isEmpState,
					this.empType,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		Import() {
			modalHelper
				.create(
					ImportExcelModel,
					{},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {});
		},
		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
