<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>{{ l('导入打卡') }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.您选择导入的Excel表的工号数据必须在系统中真实存在且打卡时间格式正确(例:2020-01-01 8:00)
                </a-row>
                <a-row class="text">
                    2.表格列顺序可不一致
                </a-row>
                <a-row class="text">
                    3.导入文件必须是xls,xlsx格式
                </a-row>
                <a-row class="text">
                    <a-col :span="5">
                        <a-button
                                @click="ExportTemplate()"
                        >
                            <a-icon type="file-excel"/>
                            <span>{{ l("下载模板") }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                                :action="uploadPictureUrl"
                                :beforeUpload="beforeUpload"
                                :headers="uploadHeaders"
                                @change="uploadPictureChange($event)">
                            <a-button type="primary"> <a-icon type="import" /> 导入 </a-button>
                        </a-upload>
                    </a-col>


                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {CheckInRecordServiceProxy} from "../../../../shared/service-proxies";
    import {fileDownloadService} from "../../../../shared/utils";
    import {AppConsts} from "../../../../abpPro/AppConsts";

    export default {
        name: "import-excel-model",
        mixins:[ModalComponentBase],
        data(){
            return{
                zh_CN,
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken()
                },
            }
        },
        created() {
            this._checkInRecordServiceProxy = new CheckInRecordServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {

        },
        methods:{
            ExportTemplate(){
                this._checkInRecordServiceProxy.exportTemplate()
                .then(res=>{
                    this._fileDownloadService.downloadTempFile(res);
                })
            },
            beforeUpload(file) {
                let filetype;
                if(file.name){
                    filetype=file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel=filetype===".xlsx" ||
                    filetype===".xls";
                if(!isExcel){
                    return abp.message.warn("只能上传xlsx格式和xls的文件");
                }

            },
            uploadPictureChange({file, fileList}){
                if (file.status === 'done') {
                    let filename=file.response.result.fileName;
                    this.loading=true;
                    this._checkInRecordServiceProxy.importExecl(filename)
                    .finally(()=>{this.loading=false;})
                    .then(res=>{
                        if(!res.isSuccess&&res.failedCount>0){
                            this.$notification["warn"]({
                                message: "总条数:"+res.totalCount+"  失败行数:"+res.failedCount,
                            });
                            this._fileDownloadService.downloadTempFile(res.file);
                        }else {
                            this.$notification["success"]({
                                message: this.l("导入成功"),
                            });
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .text{
        padding: 5px 0px;
    }
</style>
